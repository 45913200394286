import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, GREEN, PRIMARY } from '@theme_color';
import { CreateMargin, CreatePadding } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: 960,
        },
        width: ' 100%',
        alignSelf: 'center',
    },
    boxField: {
        marginTop: '0px',
        // marginBottom: '20px',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto',
        backgroundColor: 'white',
    },
    pageTitle: {
        marginBottom: 0,
    },
    address_shipping: {
        ...CreatePadding(15, 15, 15, 15),
        width: '100%',
        margin: 0,
    },
    address_billing: {
        padding: '20px 15px',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
    },
    address_title: {
        color: PRIMARY,
        fontSize: '12px',
        fontWeight: '700',
        marginBottom: '5px',
    },
    address_content: {
        fontSize: '12px',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        paddingBottom: '15px',
    },
    address_text: {
        fontSize: '12px',
    },
    address_edit: {
        cursor: 'pointer',
        marginLeft: '57.99px',
        textDecoration: 'underline',
        fontSize: '12px',
    },
    address_action: {
        padding: '15px',
    },
    address_add: {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid black',
        fontSize: '12px',
    },
    address_save: {
        width: '100%',
        backgroundColor: PRIMARY,
        color: 'white',
        textTransform: 'uppercase',
    },
    address_drawer: {
        left: 0,
        width: '100%',
    },
    address_form: {
        padding: '15px',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: '80vh',
        },
    },
    form_input: {
        marginBottom: '25px',
    },
    addBtn: {
        ...CreateMargin(30, 0, 30, 0),
    },
    boxMap: {
        ...CreateMargin(0, 0, 60, 0),
        height: 'auto',
    },
    fontWhite: {
        color: 'white',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        textAlign: 'center',
    },
    buttonProgress: {
        color: PRIMARY,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    addBtnSuccess: {
        backgroundColor: GREEN,
        '&:hover': {
            backgroundColor: GREEN,
        },
        ...CreateMargin(30, 0, 30, 0),
    },
    autoComplete: {
        marginBottom: '30px !important',
        '& .MuiInputBase-root': {
            padding: '10px 14px !important',
            alignItems: 'center',
            borderRadius: '6px',
            border: '1px solid #CCC',
            background: '#FFF',
            marginTop: 26,
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .MuiInputLabel-asterisk': {
            color: '#FF0404 !important',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
            padding: '0',
            backgroundColor: '#fff',
            minHeight: '22px',
            fontSize: 14,
        },
        '& .MuiAutocomplete-endAdornment': { 
            backgroundColor: '#fff' 
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': { 
            backgroundColor: '#fff'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none !important',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            lineHeight: '20px',
            marginBottom: 6,
            fontSize: 14,
            fontWeight: 500,
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginRight: 6,
        },
        '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
            color: '#9C9C9C',
        },
        '&.addressForm-country-autoComplete': {
            marginBottom: '10px !important',
            marginTop: '10px !important',
            '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
                marginTop: 17,
            },
        },
    },
    disabledWithArrow: {
        '& .MuiInputBase-root:before': {
            content: '""',
            backgroundImage: 'url(/assets/img/icons/arrow-down-gray.svg)',
            width: 24,
            height: 24,
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            zIndex: 1,
            left: 'calc(100% - 33px)',
            bottom: '9px',
            backgroundSize: '17px',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center'
        }
    }
}));

export default useStyles;
