import { makeStyles } from '@material-ui/core/styles';
import { CreateMargin } from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        // maxHeight: 100,
        ...CreateMargin(0, 0, 10, 0),
    },
    autoComplete: {
        marginTop: '26px !important',
        '& .MuiInputBase-root': {
            display: 'flex',
            width: '100%',
            padding: '10px 14px !important',
            alignItems: 'center',
            borderRadius: '6px',
            border: '1px solid #CCC',
            background: '#FFF',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
            padding: '0',
            backgroundColor: '#fff',
            minHeight: '22px',
            fontSize: 14,
        },
        '& .MuiAutocomplete-clearIndicator': {
            backgroundColor: '#fff',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            backgroundColor: '#fff',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none !important',
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginRight: 6,
        },
        '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
            color: '#9C9C9C',
        },
        '&>.MuiTextField-root>.MuiFormLabel-root': {
            fontSize: '14px',
            transform: 'none',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            textTransform: 'capitalize',
            marginTop: '-10px',
        },
        "& input::placeholder": {
            fontSize: '14px !important',
        }
    },
    label: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        marginBottom: 6,
        transform: 'none',
        '& .MuiFormLabel-asterisk': {
            color: '#FF0404',
        },
    },
}));

export default useStyles;
